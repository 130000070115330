import { render, staticRenderFns } from "./processThree.vue?vue&type=template&id=52c4d6ea&scoped=true&"
import script from "./processThree.vue?vue&type=script&lang=js&"
export * from "./processThree.vue?vue&type=script&lang=js&"
import style0 from "./processThree.vue?vue&type=style&index=0&id=52c4d6ea&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52c4d6ea",
  null
  
)

export default component.exports